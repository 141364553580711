import Glide from "@glidejs/glide";
let glide = null;

function initImageGallery(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        bound: true,
        startAt: 0,
        gap: 0,
        perView: 1,
    });
    let images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        glide.mount();
    }
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initImageGallery = initImageGallery;