const select = document.querySelector(
  ".post-archive .categories-filter .current-item"
);

if (select) {
  select.addEventListener("click", function (e) {
    e.preventDefault();
    if (this.parentNode.classList.contains("open")) {
      this.parentNode.classList.remove("open");
    } else {
      this.parentNode.classList.add("open");
    }
  });
}